/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { RequiredIcon } from '@/assets';
import { Tooltip } from 'antd';
import { InfoCircle } from 'iconsax-react';

interface IMixTitle {
  title?: string | null;
  isRequired?: boolean;
  tooltipValue?: string | null;
  titleWeight?: number;
}

export const MixTitle: React.FC<IMixTitle> = ({ title, tooltipValue, titleWeight, isRequired }) => {
  return (
    <div className="form-label" style={{ fontWeight: titleWeight ? titleWeight : 600 }}>
      <span>{title}</span>
      {tooltipValue && (
        <Tooltip title={tooltipValue} className="form-label__tooltip">
          <div>
            <InfoCircle size="16" color="#5a6178" />
          </div>
        </Tooltip>
      )}
      {isRequired && (
        <div className="form-label__required">
          <RequiredIcon />
        </div>
      )}
    </div>
  );
};
